import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
export default () => {
  const data = useStaticQuery(graphql`
    query is2 {
      image1: file(relativePath: { eq: "components/pages/index/is2/p1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "components/pages/index/is2/p2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "components/pages/index/is2/p3.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "components/pages/index/is2/p4.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <h5 className="title-heading">
        LATEST IN <span>ocso</span>
      </h5>

      <div className="is2">
        <div>
          <div>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </div>
          <p>Jacket</p>
        </div>
        <div>
          <div>
            <Img fluid={data.image2.childImageSharp.fluid} />
          </div>
          <p>Tshirts</p>
        </div>
        <div>
          <div>
            <Img fluid={data.image3.childImageSharp.fluid} />
          </div>
          <p>Tshirts</p>
        </div>
        <div>
          <div>
            <Img fluid={data.image4.childImageSharp.fluid} />
          </div>
          <p>Lower</p>
        </div>
      </div>
    </>
  );
};
