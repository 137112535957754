import React from "react";
// import Hero from "../components/pages/index/hero/hero";
import Slider from "../components/pages/index/slider/slider";
import Is0 from "../components/pages/index/is0/is0";
import Is1 from "../components/pages/index/is1/is1";
import Is2 from "../components/pages/index/is2/is2";
import Is3 from "../components/pages/index/is3/is3";
import Is4 from "../components/pages/index/is4/is4";
import Is5 from "../components/pages/index/is5/is5";
import Head from "../components/head";

const IndexPage = () => (
  <>
    <Head dis="Set up as Gujarat's finest IELTS Training Institute with dedicated, Student Centric faculty and adopting 21st Century training techniques imparting both Online/Offline Training." />
    <Slider />
    {/* <Hero /> */}
    <Is0 />
    <Is1 />
    <Is2 />
    <Is3 />
    <Is4 />
    <Is5 />
  </>
);

export default IndexPage;
