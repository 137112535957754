import React from "react";

export default () => {
  return (
    <>
      <div className="is1">
        <div>
          <h5>
            <span> WORLD </span> CLASS FITNESS WEAR
          </h5>
          <div className="line"></div>
          <p>
            At OCSO fitness wear, everything we do from top to bottom is of the
            highest quality to ensure we’re equipped to help you Get best
            comfort in any situation.
          </p>
        </div>
      </div>
    </>
  );
};
