import React from "react";

export default () => {
  return (
    <>
      <div className="is0">
        <div>
          <h5>JACKETS</h5>
        </div>
        <div>
          <h5>T-SHIRT</h5>
        </div>
        <div>
          <h5>SHORTS</h5>
        </div>
        <div>
          <h5>TRACK PANT</h5>
        </div>
        <div>
          <h5>SHANDOW</h5>
        </div>
      </div>
    </>
  );
};
