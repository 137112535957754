import React from "react";

export default () => {
  return (
    <>
      <div className="is3">
        <h5>
          Why <span>OCSO</span> ?
        </h5>
        <div></div>
        <p>
          <span>OCSO</span> believe in only one thing….
        </p>
        <h5>Quality</h5>
      </div>
    </>
  );
};
