import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default () => {
  return (
    <>
      <Carousel
        autoPlay={true}
        emulateTouch={true}
        infiniteLoop={true}
        interval={2000}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
      >
        <div>
          <img src="/1.jpg" />
        </div>
        <div>
          <img src="/2.jpg" />
        </div>
        <div>
          <img src="/3.jpg" />
        </div>
        <div>
          <img src="/4.jpg" />
        </div>
        <div>
          <img src="/5.jpg" />
        </div>
      </Carousel>
    </>
  );
};
