import React from "react";

export default () => {
  return (
    <>
      <div className="is5 container">
        <div className="is5-upper">
          <div className="is5-large">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/5oMTSqVooHs"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="true"
            ></iframe>
          </div>
          <div className="is5-small">
            <div className="is5-small1"></div>
            <div className="is5-small2"></div>
          </div>
        </div>
        <div className="is5-lower">
          <div>
            <p>
              A great company starts with a great product and ends with a great
              product.
            </p>
          </div>
          <div>
            <p>
              I try as much as possible to give you a great basic product and
              what comes out, I feel, is really amazing.
            </p>
          </div>
          <div>
            <p>
              The ability of a successful company to add functionality to its
              product has long been upheld.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
