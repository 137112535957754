import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
export default () => {
  const data = useStaticQuery(graphql`
    query is4 {
      image1: file(relativePath: { eq: "components/pages/index/is4/p1.JPG" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "components/pages/index/is4/p2.JPG" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "components/pages/index/is4/p3.JPG" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "components/pages/index/is4/p4.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  console.log(data);
  return (
    <>
      <h5 className="title-heading">
        <span>ocso </span> Choices
        <div></div>
      </h5>
      <div className="is2">
      <Link to='/jackets/uk116'> <div>
        
          <div>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </div>
          
          <p>UK-116</p>
        </div></Link>
        <Link to='/tshirts/tck158'><div>
          <div>
            <Img fluid={data.image2.childImageSharp.fluid} />
          </div>
          <p>TCK-158</p>
        </div></Link>
        <Link to='/tshirts/tpk162'> <div>
          <div>
            <Img fluid={data.image3.childImageSharp.fluid} />
          </div>
          <p>TPK-162</p>
        </div></Link>
        <Link to='/shandow/sk95/'>  <div>
          <div>
            <Img fluid={data.image4.childImageSharp.fluid} />
          </div>
          <p>SK-95</p>
        </div></Link>
      </div>
    </>
  );
};
